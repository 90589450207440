.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #282c34;
  color: white;
}

.nav ul {
  list-style: none;
  display: flex;
}

.nav ul li {
  margin: 0 10px;
}

.nav ul li a {
  color: white;
  text-decoration: none;
}
