/* Main container styles */
.contact-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 50px;
  flex-wrap: wrap;
}

.form-container, .details-container {
  flex: 1;
  margin: 20px;
  max-width: 500px;
  min-height: max-content;
}

.form-container {
  background: #fff;
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.thank-you {
  text-align: center;
}

.inputBox {
  position: relative;
  margin-bottom: 40px;
}

.inputBox input,
.inputBox textarea {
  width: 100%;
  padding: 10px;
  background: none;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  color: #333;
  font-size: 16px;
  transition: 0.3s ease;
}

.inputBox input:focus,
.inputBox textarea:focus,
.inputBox input:valid,
.inputBox textarea:valid {
  border-bottom-color: #3498db;
}

.inputBox span {
  position: absolute;
  left: 0;
  top: 10px;
  pointer-events: none;
  font-size: 16px;
  color: #333;
  transition: 0.3s ease;
}

.inputBox input:focus ~ span,
.inputBox textarea:focus ~ span,
.inputBox input:valid ~ span,
.inputBox textarea:valid ~ span {
  transform: translateY(-20px);
  font-size: 12px;
  color: #3498db;
}

input[type="submit"] {
  width: 100%;
  background: #3498db;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  border-radius: 8px;
  transition: 0.3s ease;
}

input[type="submit"]:hover {
  background: #2980b9;
}

.details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-info {
  background: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.info-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  text-align: left; /* Align text to the left */
}

.info-box i {
  margin-right: 10px;
}

.info-box h4, .info-box p {
  margin: 0;
  margin-left: 10px;
  font-size: 18px;
  color: #333;
  text-align: left; /* Ensure text is left-aligned */
}

.info-box p {
  font-size: 14px;
  color: #666;
}

.info-box h4 {
  font-size: 18px;
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    align-items: center;
  }

  .form-container, .details-container {
    max-width: 100%;
  }

  .info-box {
    flex-direction: row; /* Keep row direction */
    align-items: center; /* Align items to center */
  }

  .info-box h4, .info-box p {
    margin-left: 10px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .inputBox input,
  .inputBox textarea {
    font-size: 14px;
  }

  .inputBox span {
    font-size: 14px;
  }

  .info-box h4 {
    font-size: 16px;
  }

  .info-box p {
    font-size: 12px;
  }
}
