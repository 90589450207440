.about-container {
  padding: 2rem;
  text-align: center;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.subtitle {
  font-size: 1.5rem;
  color: #666;
  margin-bottom: 2rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-container {
  max-width: 800px;
  margin-bottom: 2rem;
  text-align: left;
}

.text {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.text strong {
  font-weight: 600; /* Slightly lighter bold */
  color: #333; /* Dark grey color instead of black */
}

.services-list {
  list-style: disc;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.services-list li {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0.5rem 0;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.image-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
}

.image-card.square {
  width: 150px;
  height: 150px;
}

.image-card.rectangle {
  width: 300px;
  height: 150px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.icon-container {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  flex-wrap: wrap; /* Wrap icons if needed */
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem 1rem;
  font-size: 1.5rem;
}

.icon span {
  margin-top: 0.5rem;
  font-weight: bold;
}

.icon.users svg {
  color: #4CAF50; /* Green color for users icon */
}

.icon.cog svg {
  color: #2196F3; /* Blue color for cog icon */
}

.icon.star svg {
  color: #FF9800; /* Orange color for star icon */
}

.icon.trophy svg {
  color: #f44336; /* Red color for trophy icon */
}

/* Responsive Design */
@media (min-width: 768px) {
  .content {
    flex-direction: row;
    align-items: flex-start;
  }

  .text-container {
    flex-basis: 50%;
  }

  .image-container {
    flex-basis: 40%;
  }

  .image-card {
    flex-basis: calc(50% - 1rem); /* Two images per row with 1rem gap */
  }
}

@media (min-width: 480px) {
  .image-card {
    flex-basis: 100%; /* One image per row */
  }

  .icon-container {
    justify-content: space-between;
  }
}

@media (min-width: 1024px) {
  .title {
    font-size: 3rem;
  }

  .subtitle {
    font-size: 2rem;
  }

  .text {
    font-size: 1.1rem;
  }
}
