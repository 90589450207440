.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c2c2c; /* Updated background color */
  color: lightblue; /* Updated text color */
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo img {
  max-height: 50px;
  padding-left: 10px;
}

.nav {
  display: flex;
  align-items: center;
  transition: left 0.3s ease-in-out;
}

.nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.nav ul li {
  margin: 0;
  padding: 15px 0; /* Added padding to space out menu items */
  text-align: center;
}

.nav ul li a {
  color: lightblue;
  text-decoration: none;
  display: block;
  width: 100%;
}

.menu-toggle {
  display: none;
  cursor: pointer;
  z-index: 1001;
}

.get-started {
  display: none;
}

@media (max-width: 768px) {
  .nav {
    position: fixed;
    top: 0;
    left: -250px; /* Initially hide the nav off-screen */
    width: 250px; /* Adjust the width as needed */
    height: 100vh; /* Full viewport height */
    background-color: #2c2c2c; /* Matching background color */
    overflow: hidden;
    flex-direction: column;
    z-index: 1;
  }

  .nav.open {
    left: 0; /* Show the nav on-screen */
  }

  .nav ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center the menu items vertically */
  }

  .menu-toggle {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
