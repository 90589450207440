/* Main footer styles */
.footer {
  background-color: #2c2f33;
  color: #ffffff;
  padding: 40px 20px;
  font-family: 'Arial', sans-serif;
}

/* Footer container styles */
.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0 20px;
}

/* Footer left section styles */
.footer-left {
  flex: 1;
  min-width: 250px;
  margin-bottom: 20px;
}

.footer-logo {
  max-width: 150px;
  margin-bottom: 20px;
}

.footer-left address {
  font-style: normal;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Footer social links styles */
.footer-social {
  margin-top: 20px;
}

.footer-social a {
  color: white;
  margin-right: 15px;
  font-size: 24px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-social a:hover {
  color: #1da1f2;
}

/* Footer right section styles */
.footer-right {
  flex: 2;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.footer-section {
  min-width: 150px;
  margin-bottom: 20px;
}

.footer-section h4 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #ffdd57;
}

.footer-section a {
  color: white;
  text-decoration: none;
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  transition: color 0.3s ease;
}

.footer-section a:hover {
  color: #ffdd57;
}

/* Footer bottom styles */
.footer-bottom {
  text-align: center;
  margin-top: 20px;
  border-top: 1px solid #444;
  padding-top: 20px;
}

.footer-bottom p {
  margin: 0;
  font-size: 14px;
  color: #bbb;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-right {
    justify-content: center;
    gap: 40px; /* Add more gap between sections */
  }
}

@media (max-width: 480px) {
  .footer-section h4 {
    font-size: 18px;
  }

  .footer-section a {
    font-size: 14px;
  }
}
